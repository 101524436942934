<template>
  <div id="employeeTransfer" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >TRANSFERENCIA DE EMPLEADOS</v-row
      >
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="4" sm="6" class="transfer">
            <v-row>
              <div class="form-group mt-2">
                <label for="dCompany">Empresa Pagadora Destino</label>
                <select name="dCompany" id="dCompany" v-model="dCompany">
                  <option disabled value="0">Empresa Destino</option>
                  <option
                    v-for="company in enterprises"
                    :key="company.id"
                    v-bind:value="company.id"
                  >
                    {{ company.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="oCompany">Empresa Pagadora Origen</label>
                <select name="oCompany" id="oCompany" v-model="oCompany">
                  <option disabled value="0">Selecciona Origen</option>
                  <option
                    v-for="company in enterprises"
                    :key="company.id"
                    v-bind:value="company.id"
                  >
                    {{ company.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="employeeNumber">Número de Empleado</label>
                <input
                  type="text"
                  name="employeeNumber"
                  id="employeeNumber"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="employeeNumber"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="rfc"
                  required
                  maxlength="13"
                  @keypress="validaRfc($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-2"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <router-link to="/employeeBlacklists" id="backEmployee">
                <button class="botonAmarilloDerecho">Regresar</button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">TRANSFERENCIA DE EMPLEADO</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">TRANSFERENCIA</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      enterprises: [],
      errors: [],
      error: false,
      respuesta: "",
      advice: false,
      confirmation: false,
      employeeNumber: "",
      rfc: "",
      dCompany: localStorage.empresaIdGlobal,
      oCompany: 0,
    };
  },
  methods: {
    //restablece los valores
    cancelar() {
      this.dCompany = 0;
      this.oCompany = 0;
      this.employeeNumber = 0;
      this.rfc = "";
      this.advice = false;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validaRfc() {
      var strCorrecta;
      strCorrecta = this.rfc;
      if (this.rfc.length == 12) {
        var valid = "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        //console.log("Correcto");
        return true;
      }
    },
    //validación para enviar los datos
    validacion() {
      this.errors = [];
      this.message = "Transferencia de Empleado";
      if (this.oCompany == "" || this.oCompany == 0) {
        this.errors.push("Se debe especificar la empresa orígen.");
      }
      if (this.dCompany == "" || this.dCompany == 0) {
        this.errors.push("Se debe especificar la empresa destino.");
      }
      if (this.dCompany == this.oCompany) {
        this.errors.push(
          "La empresa origen y destino no pueden ser las mismas"
        );
      }
      if (this.employeeNumber == "" || this.employeeNumber == 0) {
        this.errors.push("Se debe especificar el número de empleado.");
      }
      this.validaRfc();
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la transferencia del empleado.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    guardar() {
      //console.log("API PARA GUARDAR TRANSFERENCIA");
      this.advice = false;
      this.show = true;
      axios
        .get(
          Server +
            "/empleados/transferencia" +
            "?empresaOrigenId=" +
            this.oCompany +
            "&empresaDestinoId=" +
            this.dCompany +
            "&empleadoId=" +
            this.employeeNumber +
            "&rfc=" +
            this.rfc,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.show = false;
          //console.log(response);
          this.dCompany = 0;
          this.oCompany = 0;
          this.employeeNumber = 0;
          this.rfc = "";
          this.message = "Confirmación";
          this.respuesta = "El Empleado fue transferido con éxito";
          this.confirmation = true;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.dCompany = 0;
            this.oCompany = 0;
            this.employeeNumber = 0;
            this.rfc = "";
            this.message = "Aviso";
            this.respuesta =
              "Los datos ingresados son incorrectos o el empleado es baja";
            this.confirmation = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>